<template>
  <v-app>
    <!-- OFFLINE VIEW -->
    <v-container fill-height fluid v-if="!isOnline">
      <v-row>
        <v-col class="d-flex align-center justify-center fade-out">
          <span>It appears that you are offline...</span>
        </v-col>
      </v-row>
      <v-spacer />
    </v-container>

    <!-- LOADING SPINNER -->
    <v-container fill-height fluid v-else-if="isLoading">
      <v-row>
        <v-col class="d-flex align-center justify-center fade-out">
          <v-progress-circular
            :size="100"
            :width="10"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-spacer />
    </v-container>

    <!-- MAIN CONTENT -->
    <v-container v-else class="pa-0 mt-5 fade-in">
      <!-- LOGO & PLAYER -->
      <v-container
        class="playerContainer"
        :class="{
          darkBackground: this.$vuetify.theme.dark,
          lightBackground: !this.$vuetify.theme.dark,
        }"
        :style="{
          background: $vuetify.theme.themes.dark.background,
          top: playerContainerTop,
        }"
      >
        <!-- LOGO -->
        <v-row class="mt-n3 mt-md-n7 prevent-select">
          <img
            alt="Platinum Couch Productions Logo"
            class="mb-0 platinumCouchLogo prevent-select"
            height="167px"
            :src="logo"
            :style="{
              opacity: logoOpacity,
            }"
          />
        </v-row>

        <!-- PLAYER -->
        <v-row class="mt-0 player">
          <v-container justify="center">
            <!-- TRACK TITLE -->
            <v-row class="mx-2 mt-1 mt-md-3 mb-md-1">
              <div class="text--primary trackTitle prevent-select">
                {{ currentTrackName }}
              </div>
            </v-row>

            <!-- SEEK -->
            <v-row
              class="mx-2 progressSlider"
              @mousedown="progressSliderTapped"
              @touchstart="progressSliderTapped"
            >
              <v-slider
                v-model="currentTrackProgress"
                @change="userClickedOnProgressSlider"
                @mousedown="progressSliderTapped"
                @touchmove="progressSliderTapped"
                @mouseup="progressSliderReleased"
                @touchend="progressSliderReleased"
                @drag="progressSliderTapped"
                @drag-end="progressSliderReleased"
                color="primary"
                track-color="tertiary"
                hide-details
              />
            </v-row>

            <!-- TRACK TIMES -->
            <v-row
              justify="space-between"
              class="trackTimes mx-3 caption text--secondary prevent-select"
            >
              <div>{{ currentTrackPosition }}</div>
              <div>{{ currentTrackDuration }}</div>
            </v-row>

            <!-- TRACK CONTROL BUTTONS -->
            <v-row
              justify="center"
              align="center"
              class="trackControlButtons prevent-select"
            >
              <!-- SHUFFLE -->
              <v-btn
                @click="toggleShuffle"
                class="mx-2"
                icon
                color="secondary"
                :outlined="isShuffleOn"
              >
                <v-icon> mdi-shuffle </v-icon>
              </v-btn>
              <!-- PREVIOUS -->
              <v-btn
                @click="previousTrackClicked"
                class="mx-2"
                icon
                x-large
                color="secondary"
              >
                <v-icon> mdi-skip-previous </v-icon>
              </v-btn>
              <!-- PLAY/PAUSE -->
              <v-btn v-if="isTrackLoading" class="mx-2" icon x-large disabled>
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-btn>

              <v-btn
                v-else
                @click="playOrPause"
                class="mx-2"
                icon
                x-large
                color="secondary"
              >
                <v-icon> {{ isPlaying ? "mdi-pause" : "mdi-play" }} </v-icon>
              </v-btn>
              <!-- NEXT -->
              <v-btn
                @click="nextTrackClicked"
                class="mx-2"
                icon
                x-large
                color="secondary"
              >
                <v-icon> mdi-skip-next </v-icon>
              </v-btn>
              <!-- REPEAT -->
              <v-btn
                @click="toggleRepeat"
                class="mx-2"
                icon
                color="secondary"
                :outlined="repeatMode > 0"
              >
                <v-icon> {{ repeatIcon }} </v-icon>
              </v-btn>
            </v-row>

            <!-- SEARCH BAR -->
            <v-row
              v-if="isShowingSearchBar"
              class="align-center"
              fixed
              id="searchBar"
            >
              <v-text-field
                @focus="userStartedTyping"
                @input="searchBarValueChanged"
                class="ml-4 mr-1 searchBarTextField"
                label="Search for a track"
                color="secondary"
                v-model="searchQuery"
              ></v-text-field>

              <v-tooltip :disabled="$vuetify.breakpoint.smAndDown" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    icon
                    @click="clearSearchResults"
                    class="mr-3 my-1 deactivateButtonBugFix"
                    depressed
                  >
                    <v-icon v-on="on"> mdi-close </v-icon>
                  </v-btn>
                </template>
                <span>Clear search results</span>
              </v-tooltip>
            </v-row>

            <!-- TRACK LIST CAPTIONS -->
            <v-row
              justify="space-between"
              class="trackListCaptions px-4 mb-md-1 text-overline font-weight-thin prevent-select"
            >
              <caption>
                Track Name
              </caption>
              <caption>
                Duration
              </caption>
            </v-row>
          </v-container>
        </v-row>
      </v-container>

      <!-- TRACK LIST -->
      <div
        class="trackListContainer prevent-select"
        :style="{ 'margin-top': trackListContainerMarginTop }"
      >
        <div
          class="trackListItemContainer fade-in"
          v-for="(item, index) in recordings"
          :key="item.id"
        >
          <!-- YEAR LABEL -->
          <div
            class="yearLabel mr-4 font-weight-thin text--disabled d-flex align-center justify-end"
            v-if="shouldShowYear(item)"
            @click="toggleCollapseExpandYear(item.date.slice(0, 4))"
          >
            <v-icon
              v-if="yearsCollapsed.includes(item.date.slice(0, 4))"
              class="mx-3 text--disabled"
              :small="$vuetify.breakpoint.xs"
            >
              mdi-chevron-left
            </v-icon>
            <v-icon
              v-else
              class="mx-3 text--disabled"
              :small="$vuetify.breakpoint.xs"
            >
              mdi-chevron-down
            </v-icon>

            <span>{{ item.date.slice(0, 4) }}</span>
          </div>
          <!-- TRACK -->
          <div
            v-if="shouldShowTrack(item)"
            :id="'trackID' + item.id"
            class="trackListItem pl-4 deactivateButtonBugFix"
            :class="{
              trackListItemActive: trackListItemActive(item.id),
              trackListItemExtraPadding: !isCreatePlaylistModeOn,
            }"
            @click="trackClicked(item, index)"
          >
            <div
              class="trackListItemsDetails d-flex justify-space-between align-center"
            >
              <span class="trackListItemName">{{ item.name }}</span>
              <v-checkbox
                v-if="isLoggedIn && isCreatePlaylistModeOn"
                v-model="selectedTracks[item.id]"
                class="mr-2 playlistCheckbox"
                @click.native.stop
              />
              <span
                v-if="!isCreatePlaylistModeOn"
                class="trackListItemDuration mr-4"
                >{{ item.duration }}</span
              >
            </div>
          </div>
        </div>
        <v-row
          v-if="isUserTyping && searchResults.length == 0"
          class="justify-center mt-11"
        >
          <caption class="font-weight-light prevent-select noResults">
            No results, please check your search query...
          </caption>
        </v-row>
      </div>
    </v-container>

    <!-- <v-list-item-action class="d-flex justify-end">
            <div>
              {{ item.numberOfListens.toLocaleString() }}
            </div>
          </v-list-item-action> -->

    <!-- TOP BUTTONS: AUDIO QUALITY, CREATE PLAYLIST, SORT, UPLOAD, DARK/LIGHT MODE, and LOGIN/LOGOUT -->
    <div v-if="!isLoading && isOnline" class="topButtons fade-in">
      <v-row class="justify-space-between pa-3">
        <div
          class="topButtonsLeft ml-2 ml-md-6 pa-3 d-flex flex-column align-end"
        >
          <!-- HIGH-QUALITY AUDIO SWITCH -->
          <v-row class="d-flex align-center highQualityAudioSwitchContainer">
            <span class="switchCaption">high-quality audio: </span>
            <v-switch
              v-model="isHighQualityAudioModeOn"
              class="ml-2 topButtonsSwitch"
              hide-details
            ></v-switch>
          </v-row>
          <!-- CREATE PLAYLIST MODE SWITCH -->
          <v-row
            v-if="isLoggedIn"
            class="d-flex align-center createPlaylistSwitchContainer"
          >
            <span class="switchCaption">create playlist: </span>
            <v-switch
              v-model="isCreatePlaylistModeOn"
              class="ml-2 topButtonsSwitch"
              hide-details
            ></v-switch>
          </v-row>
        </div>

        <div class="topButtonsRight">
          <!-- HOME BUTTON -->
          <v-tooltip
            v-if="isLoggedIn && playlistID"
            :disabled="$vuetify.breakpoint.smAndDown"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                :x-small="$vuetify.breakpoint.xs"
                :small="$vuetify.breakpoint.sm"
                fab
                @click="goHome"
                class="mr-2 my-1 deactivateButtonBugFix"
              >
                <v-icon v-on="on"> mdi-home </v-icon>
              </v-btn>
            </template>
            <span>Home</span>
          </v-tooltip>

          <!-- SORT ALPHABETICALLY BUTTON -->
          <v-tooltip
            v-if="this.sort == 'chronologically' && isLoggedIn && !playlistID"
            :disabled="$vuetify.breakpoint.smAndDown"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                :x-small="$vuetify.breakpoint.xs"
                :small="$vuetify.breakpoint.sm"
                fab
                @click="sortAlphabetically"
                class="mr-2 my-1 deactivateButtonBugFix"
              >
                <v-icon v-on="on">
                  mdi-sort-alphabetical-descending-variant
                </v-icon>
              </v-btn>
            </template>
            <span>Sort Alphabetically</span>
          </v-tooltip>

          <!-- SORT CHRONOLOGICALLY BUTTON -->
          <v-tooltip
            v-if="this.sort == 'alphabetically' && isLoggedIn && !playlistID"
            :disabled="$vuetify.breakpoint.smAndDown"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                :x-small="$vuetify.breakpoint.xs"
                :small="$vuetify.breakpoint.sm"
                fab
                @click="sortChronologically"
                class="mr-2 my-1 deactivateButtonBugFix"
              >
                <v-icon v-on="on"> mdi-sort-calendar-ascending</v-icon>
              </v-btn>
            </template>
            <span>Sort Chronologically</span>
          </v-tooltip>

          <v-tooltip
            v-if="isLoggedIn && !playlistID"
            :disabled="$vuetify.breakpoint.smAndDown"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                :x-small="$vuetify.breakpoint.xs"
                :small="$vuetify.breakpoint.sm"
                fab
                @click="showOrHideSearchBar"
                class="mr-2 my-1 deactivateButtonBugFix"
              >
                <v-icon v-on="on"> mdi-magnify </v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>

          <!-- DARK MODE BUTTON -->
          <v-tooltip
            v-if="!$vuetify.theme.dark"
            :disabled="$vuetify.breakpoint.smAndDown"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                :x-small="$vuetify.breakpoint.xs"
                :small="$vuetify.breakpoint.sm"
                fab
                @click="darkMode"
                class="mr-2 my-1 deactivateButtonBugFix"
              >
                <v-icon v-on="on" class="mr-1">mdi-moon-waxing-crescent</v-icon>
              </v-btn>
            </template>
            <span>Dark Mode</span>
          </v-tooltip>

          <!-- LIGHT MODE BUTTON -->
          <v-tooltip v-else :disabled="$vuetify.breakpoint.smAndDown" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                :x-small="$vuetify.breakpoint.xs"
                :small="$vuetify.breakpoint.sm"
                fab
                @click="darkMode"
                class="mr-2 my-1 deactivateButtonBugFix"
              >
                <v-icon v-on="on">mdi-white-balance-sunny</v-icon>
              </v-btn>
            </template>
            <span>Light Mode</span>
          </v-tooltip>

          <!-- UPLOAD BUTTON -->
          <v-tooltip v-if="isLoggedIn && isSuperAdmin" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                :x-small="$vuetify.breakpoint.xs"
                :small="$vuetify.breakpoint.sm"
                fab
                @click="showUploadRecordingDialog"
                class="mr-2 my-1 deactivateButtonBugFix"
              >
                <v-icon v-on="on">mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Upload A Recording</span>
          </v-tooltip>

          <!-- LOG OUT BUTTON -->
          <v-tooltip
            v-if="isLoggedIn"
            :disabled="$vuetify.breakpoint.smAndDown"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                :x-small="$vuetify.breakpoint.xs"
                :small="$vuetify.breakpoint.sm"
                fab
                @click="
                  logout();
                  on = false;
                "
                class="mr-2 my-1 deactivateButtonBugFix"
              >
                <v-icon v-on="on">mdi-logout</v-icon>
              </v-btn>
            </template>
            <span>Log Out</span>
          </v-tooltip>

          <!-- LOG IN BUTTON -->
          <v-tooltip v-else :disabled="$vuetify.breakpoint.smAndDown" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                :x-small="$vuetify.breakpoint.xs"
                :small="$vuetify.breakpoint.sm"
                fab
                @click="
                  isShowingLoginDialog = true;
                  on = false;
                "
                class="mr-2 my-1 deactivateButtonBugFix"
              >
                <v-icon v-on="on">mdi-lock-outline</v-icon>
              </v-btn>
            </template>
            <span>Log In</span>
          </v-tooltip>
        </div>
      </v-row>
    </div>

    <!-- LOGIN DIALOG -->
    <v-dialog
      v-model="isShowingLoginDialog"
      max-width="630px"
      class="highestZIndex"
    >
      <v-card class="pa-5">
        <v-form v-model="loginFormValid" @submit.prevent="login">
          <v-text-field
            type="text"
            v-model="email"
            class="loginTextField"
            prepend-inner-icon="mdi-account"
            :rules="emailRules"
            :placeholder="!emailAutofilled ? ' ' : ''"
            v-on:keyup="checkForEnterKey"
          ></v-text-field>
          <v-text-field
            type="password"
            v-model="password"
            class="loginTextField"
            prepend-inner-icon="mdi-key-variant"
            :rules="passwordRules"
            :placeholder="!passwordAutofilled ? ' ' : ''"
            v-on:keyup="checkForEnterKey"
          ></v-text-field>
        </v-form>
        <span v-if="$store.getters.loginError" class="red--text">{{
          $store.getters.loginError
        }}</span>
        <v-btn
          block
          :large="$vuetify.breakpoint.smAndUp"
          :disabled="!loginFormValid"
          :loading="isLoggingIn"
          color="secondary"
          @click="login"
          class="mt-2"
        >
          Log in
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- UPLOAD RECORDING DIALOG -->
    <v-dialog v-model="isShowingUploadRecordingDialog" max-width="630px" class="highestZIndex">
      <v-card class="pa-5">
        <v-form ref="form" v-model="uploadRecordingFormValid">
          <v-file-input
            accept=".wav,.aif,.aiff"
            label="wav/aif/aiff"
            type="file"
            @change="handleHighQualityFileUpload($event)"
          ></v-file-input>
          <v-file-input
            accept=".mp3"
            label="mp3"
            type="file"
            @change="handleRegularQualityFileUpload($event)"
          ></v-file-input>

          <v-text-field
            v-model="title"
            :rules="titleRules"
            label="Title"
            required
            prepend-icon="mdi-file-document-edit-outline"
          ></v-text-field>

          <v-dialog
            ref="dialog"
            v-model="datePickerModal"
            :return-value.sync="uploadRecordingDate"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="uploadRecordingDate"
                label="Recording Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                @mousedown.prevent
                :rules="[(v) => !!v || 'Required']"
              ></v-text-field>
            </template>
            <v-date-picker v-model="uploadRecordingDate" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(uploadRecordingDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <v-btn
            block
            :disabled="
              !uploadRecordingFormValid || !uploadRecordingDate || highQualityFile == '' || regularQualityFile == ''
            "
            :loading="isUploading"
            color="success"
            @click="submitFiles"
            class="mt-2"
          >
            Upload
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- CREATE PLAYLIST BUTTON -->
    <v-row
      v-if="isLoggedIn && isCreatePlaylistModeOn"
      class="d-flex justify-center align-center createPlaylistButtonContainer py-2 mx-0 px-0"
      :class="{
        darkBackground: this.$vuetify.theme.dark,
        lightBackground: !this.$vuetify.theme.dark,
      }"
    >
      <v-btn
        :disabled="createPlaylistButtonDisabled"
        @click="createPlaylist"
        color="secondary"
        class="createPlaylistButton mb-4"
        small
      >
        Create Playlist
      </v-btn>
    </v-row>

    <!-- PLAYLIST CREATED DIALOG -->
    <v-dialog
      :value="isShowingPlaylistCreatedDialog"
      max-width="630px"
      class="highestZIndex"
    >
      <v-card
        class="pa-5"
        v-on-clickaway="clickedAwayFromPlaylistCreatedDialog"
      >
        <v-alert icon="mdi-web" text class="playlistCreatedURL" type="info">{{
          newPlaylistURL
        }}</v-alert>
        <v-row class="d-flex align-center">
          <v-col>
            <v-btn color="secondary" block outlined @click="copyToClipboard">
              Copy To Clipboard
            </v-btn>
          </v-col>
          <v-col>
            <v-btn color="secondary" block depressed @click="openInANewTab">
              Open In A New Tab
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- SNACKBAR/TOAST/NOTIFICATION -->
    <v-snackbar
      v-model="isShowingSnackbar"
      :timeout="snackbarTimeout"
      content-class="text-center"
    >
      {{ snackbarText }}
    </v-snackbar>
  </v-app>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";

export default {
  props: {
    playlistID: String,
  },

  mixins: [clickaway],

  data() {
    return {
      loader: null,
      sort: "chronologically",
      isHighQualityAudioModeOn: false,
      isShowingLoginDialog: false,
      isShowingUploadRecordingDialog: false,
      isCreatePlaylistModeOn: false,
      uploadRecordingFormValid: false,
      loginFormValid: false,
      isLoggingIn: false,
      title: "",
      titleRules: [
        (v) => !!v || "Title is required",
        (v) =>
          (v && v.length <= 250) || "Title must be less than 250 characters",
      ],
      highQualityFile: "",
      regularQualityFile: "",
      email: "",
      password: "",
      emailRules: [
        (value) => !!value || "Required.",
        (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (pattern.test(value)) {
            return true;
          } else {
            return "Invalid e-mail.";
          }
        },
      ],
      passwordRules: [(value) => !!value || "Required."],
      emailAutofilled: false,
      passwordAutofilled: false,
      uploadRecordingDate: null,
      dateForMenu: null,
      datePickerMenu: false,
      datePickerModal: false,
      isShowingSnackbar: false,
      snackbarText: null,
      snackbarTimeout: 3000,
      loginErrorMessage: null,
      isOnline: navigator.onLine,
      currentTrack: null,
      currentTrackIndex: 0,
      currentTrackDetails: null,
      currentTrackPosition: "0:00",
      currentTrackDuration: "0:00",
      currentTrackProgress: 0,
      isUserHoldingProgressSlider: false,
      previousTrackPosition: "0:00",
      trackReloadCounter: 0,
      sameTrackPositionCounter: 0,
      selectedTrackIndex: 0,
      isShuffleOn: false,
      isTrackLoading: true,
      isPlaying: false,
      isConnectionSlow: false,
      isShowingSearchBar: false,
      isUserTyping: false,
      shouldPreventTooManyClicks: false,
      fadeTime: 500,
      repeatMode: 0,
      yearsCollapsed: [],
      selectedTracks: [],
      trackDurations: {},
      searchQuery: "",
      searchResults: [],
      playerContainerTop: "0px",
      playerTopOffset: 50,
      logoOpacity: 1,
      longTouchTimer: null,
      scrollPosition: 0,
    };
  },

  created() {
    if (this.playlistID) {
      this.$store.commit("SET_PLAYLIST_ID", this.playlistID);
      const playlistURL = process.env.VUE_APP_BASE_URL + "/" + this.playlistID;
      this.$store.commit("SET_PLAYLIST_URL", playlistURL);
    }

    if (window.innerHeight < 844) {
      window.addEventListener("scroll", this.handleScroll);
    }

    document.addEventListener("DOMContentLoaded", function () {
      window.addEventListener("touchstart", this.touchstart, false);
      window.addEventListener("touchend", this.touchend, false);
    });
  },

  mounted() {
    // alert("window.innerWidth:" + window.innerWidth);
    // alert("iPhone detected:" + this.iPhoneDetected);
    window.addEventListener("online", () => {
      this.isOnline = true;
    });
    window.addEventListener("offline", () => {
      this.isOnline = false;
    });
    setInterval(() => {
      if (
        this.isPlaying &&
        !this.isUserHoldingProgressSlider &&
        this.previousTrackPosition == this.currentTrackPosition
      ) {
        if (this.sameTrackPositionCounter > 2) {
          if (this.trackReloadCounter > 2) {
            // console.log("*** loading next track")
            this.trackReloadCounter = 0;
            this.nextTrackClicked();
          } else {
            // console.log("*** retrying")
            this.loadTrack(() => {
              setTimeout(() => {
                this.play();
              }, 300);
            });
            this.trackReloadCounter += 1;
          }
        } else {
          this.sameTrackPositionCounter += 1;
        }
      } else {
        // console.log("*** playing again, resetting counters")
        this.sameTrackPositionCounter = 0
        this.trackReloadCounter = 0
        this.previousTrackPosition = this.currentTrackPosition;
      }
    }, 4000);
  },

  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    },

    recordings() {
      const recordingsArray = this.$store.getters.recordings;

      var isAlbum = true
      
      for (let i = 0; i < recordingsArray.length; i++) {
        if (recordingsArray[i].misc === null) {
          isAlbum = false
          break
        }
      }      

      if (isAlbum) {
        recordingsArray.sort(function (a, b) {
          return parseInt(a.misc) - parseInt(b.misc);
        });
      } else if (this.sort == "chronologically") {
        recordingsArray.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        });
      } else {
        recordingsArray.sort((a, b) => a.name.localeCompare(b.name));
      }

      return recordingsArray;
    },

    loading() {
      // TODO: do I need this?
      return this.$store.getters.recordings == [];
    },

    iPhoneDetected() {
      return /iPhone/i.test(navigator.userAgent);
    },

    trackListContainerMarginTop() {
      const base = 315;
      const searchBarHeight = 70;
      const topMargin =
        (this.isShowingSearchBar ? base + searchBarHeight : base) -
        2 * this.scrollPosition;

      var marginToSubtract = 10;
      if (window.innerWidth < 550) {
        if (window.innerWidth < 450) {
          marginToSubtract = 70;
        } else {
          marginToSubtract = 40;
        }
      }

      if (this.iPhoneDetected) {
        marginToSubtract = 65;
      }
      // window.innerWidth < 550 ? (window.innerWidth < 450 ? 80 : 40) : 10;

      // adds extra margin depending on breakpoint
      // xs: <600px, sm: 600-959px, md: 960-1263px,
      // lg: 1264-1903px, xl: 1904+
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return topMargin - marginToSubtract + "px";
        case "sm":
          return topMargin + 30 + "px";
        case "md":
          return topMargin + 55 + "px";
        case "lg":
          return topMargin + 100 + "px";
        default:
          return topMargin + 90 + "px";
      }
    },

    isUploading() {
      return this.$store.getters.isUploading;
    },

    isLoggedIn() {
      return this.$store.getters.user != null;
    },

    isSuperAdmin() {
      return this.$store.getters.isSuperAdmin;
    },

    logo() {
      return this.$vuetify.theme.dark
        ? require("../assets/logo dark theme.png")
        : require("../assets/logo.png");
    },

    currentTrackName() {
      return this.currentTrackDetails ? this.currentTrackDetails.name : " ";
    },

    currentTrackURL() {
      if (!this.currentTrackDetails || !this.currentTrackDetails.name) {
        return;
      }

      // console.log("*** this.currentTrackDetails:", this.currentTrackDetails)
      // this.currentTrackRemainingTime = this.currentTrackDetails.duration

      const url =
        process.env.VUE_APP_RECORDINGS_URL +
        this.formatURLString(this.currentTrackDetails.name) +
        "." +
        (this.isHighQualityAudioModeOn ? "wav" : "mp3");
      // console.log("*** currentTrackURL:", url)
      return url;
    },

    repeatIcon() {
      if (this.repeatMode < 2) {
        return "mdi-repeat";
      } else {
        return "mdi-repeat-once";
      }
    },

    createPlaylistButtonDisabled() {
      return this.selectedTracks.length > 0 ? false : true;
    },

    isShowingPlaylistCreatedDialog() {
      return this.$store.getters.isShowingPlaylistCreatedDialog;
    },

    playlistURL() {
      return this.$store.getters.playlistURL;
    },

    newPlaylistURL() {
      return this.$store.getters.newPlaylistURL;
    },
  },

  watch: {
    "$store.state.isLoading"(bool) {
      // done loading
      if (!bool) {
        this.currentTrackDetails = this.recordings[0];
        this.currentTrackDuration = this.recordings[0].duration;
        this.loadTrack();

        setInterval(() => {
          if (this.isPlaying && !this.isUserHoldingProgressSlider) {
            this.updateTrackProgressInfo();
          }
        }, 200);
      }
    },
    isUploading(newValue) {
      if (!newValue) {
        this.snackbarText = "Recording successfully uploaded";
        this.isShowingSnackbar = true;
        this.$store.dispatch("loadMasterPlaylist");
        this.isShowingUploadRecordingDialog = false;
      }
    },
    isLoggedIn(newValue) {
      if (newValue) {
        this.isShowingLoginDialog = false;
        this.scrollToTheTop()
      }
    },
    uploadRecordingDate(newVal) {
      this.dateForMenu = new Date(newVal).toISOString().substr(0, 10);
      this.datePickerMenu = false;
    },
    title(newValue) {
      if (newValue.includes("/") || newValue.includes("\\")) {
        this.title = newValue.replace("/", " ").replace("\\", " ");
      }
    },
    currentTrackIndex: function (newValue) {
      this.selectedTrackIndex = newValue;
    },
    searchQuery: function (searchQuery) {
      this.searchResults = [];
      const regex = new RegExp(searchQuery.toLowerCase().trim());

      this.recordings.forEach((recording) => {
        if (regex.test(recording.name.toLowerCase())) {
          this.searchResults.push(recording.id);
        }
      });
    },
  },

  methods: {
    formatURLString(str) {
      return str.replace(/ /g, "%20").replace(/\?/g, "%3F");
    },
    sortAlphabetically() {
      this.sort = "alphabetically";
      this.snackbarText = "Sorted alphabetically";
      this.isShowingSnackbar = true;
    },
    sortChronologically() {
      this.sort = "chronologically";
      this.snackbarText = "Sorted chronologically";
      this.isShowingSnackbar = true;
    },
    showOrHideSearchBar() {
      this.isShowingSearchBar
        ? this.clearSearchResults()
        : this.showSearchBar();
    },
    showSearchBar() {
      this.isShowingSearchBar = true;
      this.$vuetify.goTo(0, { container: ".trackListContainer"})
    },
    clearSearchResults() {
      this.searchQuery = "";
      this.searchResults = [];
      this.isShowingSearchBar = false;
      this.isUserTyping = false;
      this.scrollToTheTop()
    },
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showUploadRecordingDialog() {
      this.isShowingUploadRecordingDialog = true;
    },
    handleHighQualityFileUpload(event) {
      this.highQualityFile = event;
      this.$store.dispatch("setHighQualityFileToUpload", this.highQualityFile);
    },
    handleRegularQualityFileUpload(event) {
      this.regularQualityFile = event;
      this.$store.dispatch(
        "setRegularQualityFileToUpload",
        this.regularQualityFile
      );
    },
    submitFiles() {
      if (
        !this.title ||
        !this.highQualityFile ||
        !this.regularQualityFile ||
        !this.uploadRecordingDate
      ) {
        console.log("error submitting file");
        return;
      }

      this.$store.dispatch("setIsUploading", true);

      const fileInfo = {
        title: this.title,
        date: this.uploadRecordingDate.slice(0, 10),
      };

      this.$store.dispatch("uploadFiles", fileInfo)
      // .then((something) => {
      //   console.log("Couch submitFiles then something:", something)
      //   this.snackbarText = "Recording successfully uploaded";
      //   this.isShowingSnackbar = true;
      //   this.$store.dispatch("loadMasterPlaylist");
      // });
    },

    goHome() {
      if (this.isLoggedIn) {
        this.$root.$emit("playing", -1);
        this.$store.dispatch("loadMasterPlaylist").then(() => {
          this.$router.push("/");
        });
      }
    },
    async login() {
      this.isLoggingIn = true;
      try {
        await this.$store
          .dispatch("login", {
            email: this.email,
            password: this.password,
          })
          .then(() => {
            if (this.isLoggedIn && !this.playlistID) {
              this.$store.dispatch("loadMasterPlaylist").then(() => {
                this.isShowingLoginDialog = false;
                this.snackbarText = "Welcome!";
                this.isShowingSnackbar = true;
              });
            }
          });
      } catch (err) {
        console.log("unable to log in:", err);
      }
      this.isLoggingIn = false;
    },

    checkForEnterKey: function (e) {
      if (e.keyCode === 13 && this.email != "" && this.password != "") {
        this.login();
      }
    },

    userStartedTyping: function () {
      this.isUserTyping = true;
      if (window.innerWidth < 960) {
        this.$vuetify.goTo(".player", {
          offset: -15,
        });
      }
    },

    userStoppedTyping: function () {
      this.clearSearchResults();
    },

    async logout() {
      try {
        await this.$store.dispatch("logout");
        this.snackbarText = "See you later!";
        this.isShowingSnackbar = true;
      } catch (err) {
        console.log("unable to log out:", err);
      }
    },

    trackClicked(track, index) {
      if (this.currentTrackDetails.id == track.id) {
        this.currentTrack.currentTime = 0;
        return;
      }
      if (this.isPlaying) {
        this.pause();
      }

      this.currentTrackDetails = track;
      this.currentTrackIndex = index;
      this.currentTrackDuration = track.duration;

      this.loadTrack(() => {
        setTimeout(() => {
          this.play();
        }, 300);
      });
    },

    isSelected(index) {
      return this.selectedTrackIndex == index;
    },

    playOrPause() {
      if (this.isPlaying) {
        this.pause();
      } else {
        this.play();
      }
    },

    play() {
      if (this.shouldPreventTooManyClicks) {
        return;
      }
      this.currentTrack.play();
      this.isPlaying = true;
      // console.log("play isPlaying:", this.isPlaying)
      // console.log("play currentPosition:", this.currentPosition)
      this.shouldPreventTooManyClicks = true;
      setTimeout(() => {
        this.shouldPreventTooManyClicks = false;
      }, 500);
    },

    pause() {
      this.currentTrack.pause();
      this.isPlaying = false;
    },

    previousTrackClicked() {
      const currentPosition = this.currentTrack.currentTime;

      if (this.isPlaying && currentPosition >= 3) {
        this.currentTrack.currentTime = 0;
        return;
      }
      if (this.isShuffleOn) {
        this.currentTrackIndex = this.getRandomTrackIndex(
          0,
          this.recordings.length - 1
        );
      } else if (this.currentTrackIndex == 0) {
        this.currentTrackIndex = this.recordings.length - 1;
      } else {
        this.currentTrackIndex = this.currentTrackIndex - 1;
      }
      this.loadPreviousOrNextTrack();
    },

    nextTrackClicked() {
      if (this.isShuffleOn) {
        this.currentTrackIndex = this.getRandomTrackIndex(
          0,
          this.recordings.length - 1
        );
      } else if (this.currentTrackIndex == this.recordings.length - 1) {
        this.currentTrackIndex = 0;
      } else {
        this.currentTrackIndex = this.currentTrackIndex + 1;
      }
      this.loadPreviousOrNextTrack();
    },

    loadPreviousOrNextTrack() {
      if (this.isPlaying) {
        this.pause();
      }
      this.selectedTrackIndex = this.currentTrackIndex;
      setTimeout(() => {
        this.currentTrackDetails = this.recordings[this.currentTrackIndex];
        this.currentTrackDuration = this.currentTrackDetails.duration
        this.loadTrack(() => {
          setTimeout(() => {
            this.play();
          }, 300);
        });
      }, this.fadeTime);
    },

    loadTrack: function (callback) {
      if (!this.currentTrackURL || this.currentTrackURL.length == 0) {
        return;
      }
      // console.log("this.currentTrackURL:", this.currentTrackURL)
      if (
        this.searchQuery != "" &&
        !this.searchResults.includes(this.currentTrackDetails.id)
      ) {
        this.clearSearchResults();
      }
      this.isTrackLoading = true;

      if (this.currentTrack != null) {
        this.currentTrack.pause();
        this.currentTrack = null;
      }

      this.currentTrack = new Audio(this.currentTrackURL);

      // this.currentTrack.onloadedmetadata = () => {
      //   console.log("onloadedmetadata duration:", this.currentTrack.duration)
      //   // this.setState({
      //   //    duration: this.formatTime(audio.duration.toFixed(0))
      //   // })
      // }

      this.isTrackLoading = false;
      
      if (callback != null) {
        callback();
      }

      // UN-COLLAPSE YEAR IF COLLAPSED
      const trackYear = this.currentTrackDetails.date.slice(0, 4);
      if (this.yearsCollapsed.includes(trackYear)) {
        this.yearsCollapsed = this.yearsCollapsed.filter(
          (year) => year != trackYear
        );
      }

      // SCROLL TO TRACK
      setTimeout(() => {
        this.$vuetify.goTo("#trackID" + this.currentTrackDetails.id, {
          offset: 600,
        });
      }, 300);

      this.currentTrack.addEventListener("ended", () => {
        if (!this.isPlaying) {
          return;
        }

        if (this.repeatMode == 2) {
          // repeat a single track
          this.currentTrack.currentTime = 0;
          this.play();
        } else if (
          // repeat the entire playlist (and shuffle is off)
          this.repeatMode == 1 &&
          this.currentTrackIndex == this.recordings.length - 1 &&
          !this.isShuffleOn
        ) {
          this.currentTrackIndex = 0;
          this.loadPreviousOrNextTrack();
        } else if (this.currentTrackIndex == this.recordings.length - 1) {
          this.isPlaying = false;
          return;
        } else {
          this.nextTrackClicked();
        }
      });
    },

    updateTrackProgressInfo() {
      if (!this.currentTrack) {
        return;
      }
      // console.log("updateTrackProgressInfo this.currentTrack:", this.currentTrack)
      const currentPosition = this.currentTrack.currentTime;
      const duration = this.currentTrack.duration;

      // console.log("updateTrackProgressInfo currentPosition:", currentPosition)
      // console.log("updateTrackProgressInfo duration:", duration)
      // console.log("updateTrackProgressInfo currentTrackDetails.duration:", this.currentTrackDetails.duration)

      this.currentTrackPosition = this.currentTrack
        ? this.formatTime(currentPosition)
        : "0:00";

      // this.currentTrackRemainingTime = this.currentTrack
      //   ? this.formatTime(duration)
      //   : "0:00";

      // update progress bar
      this.currentTrackProgress = (currentPosition / duration) * 100;

      this.selectedTrackIndex = this.currentTrackIndex;
    },

    userClickedOnProgressSlider(newValue) {
      const timeToJumpTo = (newValue / 100) * this.currentTrack.duration;
      this.currentTrack.pause();
      this.currentTrack.currentTime = timeToJumpTo;
      if (!this.isUserHoldingProgressSlider) {
        this.updateTrackProgressInfo();
      }
      this.currentTrack.play();
    },

    toggleShuffle() {
      this.isShuffleOn = !this.isShuffleOn;
      if (this.repeatMode == 2) {
        this.toggleRepeat();
      }
    },

    toggleRepeat() {
      if (this.repeatMode == 0) {
        this.repeatMode = 1; // repeat the whole playlist
      } else if (this.repeatMode == 1) {
        this.repeatMode = 2; // repeat the current track
      } else {
        this.repeatMode = 0; // repeat is off
      }
    },

    // HELPER METHODS:

    formatTime(numberOfSeconds) {
      var date = new Date(0);
      date.setSeconds(numberOfSeconds);
      // console.log("*** formatTime numberOfSeconds:", numberOfSeconds, "date:", date)
      var timeString = date.toISOString().substring(14, 19);
      return timeString;
    },

    getRandomTrackIndex(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      var randomInt = Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
      while (randomInt == this.currentTrackIndex) {
        randomInt = Math.floor(Math.random() * (max - min + 1) + min);
      }
      return randomInt; 
    },

    shouldShowYear(item) {
      if (
        this.sort != "chronologically" ||
        this.recordings.length < 20 ||
        this.isShowingSearchBar ||
        this.searchQuery != "" 
      ) {
        return false;
      }
      return (
        item ==
        this.recordings.find(
          (recording) => recording.date.slice(0, 4) == item.date.slice(0, 4)
        )
      );
    },

    shouldShowTrack(item) {
      if (this.searchQuery != "") {
        return this.searchResults.includes(item.id);
      } else {
        return !this.yearsCollapsed.includes(item.date.slice(0, 4));
      }
    },

    toggleCollapseExpandYear(year) {
      if (this.yearsCollapsed.includes(year)) {
        this.yearsCollapsed = this.yearsCollapsed.filter((el) => el != year);
      } else {
        this.yearsCollapsed.push(year);
      }
    },

    trackListItemActive(trackID) {
      return trackID == this.currentTrackDetails.id;
    },

    createPlaylist: function () {
      const selectedTrackIDs = [];
      for (let i = 0; i < this.selectedTracks.length; i++) {
        if (this.selectedTracks[i]) {
          selectedTrackIDs.push(i);
        }
      }
      // TODO: investigate
      this.$store.dispatch("createPlaylist", selectedTrackIDs);
    },

    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.newPlaylistURL);
      } catch (err) {
        console.log("problem copying to clipboard:", err);
      }
    },

    openInANewTab: function () {
      window.open(this.newPlaylistURL);
    },

    createURL: function (recordingName) {
      return (
        process.env.VUE_APP_BASE_URL +
        "/recordings/" +
        recordingName +
        "." +
        this.fileExtension
      );
    },

    clickedAwayFromPlaylistCreatedDialog: function () {
      if (this.isShowingPlaylistCreatedDialog) {
        this.selectedTrackIDs = [];
        this.$store.dispatch("resetPlaylistConstants");
      }
    },

    clickedAwayFromSearchBox: function () {
      if (this.isShowingPlaylistCreatedDialog) {
        this.selectedTrackIDs = [];
        this.$store.dispatch("resetPlaylistConstants");
      }
    },

    handleScroll: function () {
      if (
        this.recordings.length < 10 ||
        this.isUserTyping
      ) {
        return;
      }

      const scrollY = window.scrollY;

      if (scrollY < this.playerTopOffset) {
        const newPosition = 0 - scrollY;
        this.scrollPosition = scrollY * 1.5;
        this.playerContainerTop = newPosition + "px";
        this.logoOpacity = 1 - (scrollY * 1.7) / 100;
      } else {
        this.playerContainerTop = -this.playerTopOffset + "px";
        this.logoOpacity = 0;
      }
    },

    progressSliderTapped: function () {
      this.isUserHoldingProgressSlider = true;
    },

    progressSliderReleased: function () {
      this.isUserHoldingProgressSlider = false;
    },

    touchstart: function (e) {
      e.preventDefault();
      if (!this.longTouchTimer) {
        this.longTouchTimer = setTimeout(this.onlongtouch, 300);
      }
    },

    touchend: function () {
      // stops short touches from firing the event
      if (this.longTouchTimer) {
        clearTimeout(this.longTouchTimer);
        this.longTouchTimer = null;
      }
    },

    onlongtouch: function () {
      this.longTouchTimer = null;
    },

    searchBarValueChanged: function () {
      if (window.innerWidth < 960) {
        this.$vuetify.goTo(".player", {
          offset: -15,
        });
      }
    },

    scrollToTheTop: function () {
      this.$vuetify.goTo(0)
    }
  },
};
</script>

<style>
html,
body {
  overscroll-behavior-y: none;
}

.topButtons {
  position: fixed;
  top: 10px;
  width: 100%;
  z-index: 9999;
  text-align: right;
}

.topButtonsSwitch {
  margin-top: -5px !important;
}

.deactivateButtonBugFix:focus::before {
  opacity: 0 !important;
}

.toggleGroup {
  width: 100% !important;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}

.container {
  max-width: 800px !important;
}

.platinumCouchLogo {
  margin: 0 auto;
  margin-top: 85px;
}

.playerContainer {
  position: fixed;
  width: 100%;
  z-index: 999;
}

.playhead {
  position: absolute;
  top: 0;
}

.trackTitle {
  white-space: nowrap;
  word-break: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trackListContainer {
  margin-bottom: 150px;
  width: 100%;
}

.trackListItemActive {
  background-color: var(--v-tertiary-base);
}

.trackListItemName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.trackListItemExtraPadding {
  padding: 21px 0 21px 0;
}

.createPlaylistSwitchContainer {
  margin-top: 23px !important;
}

.createPlaylistButtonContainer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: var(--v-primary-base);
}

.playlistCreatedURL {
  overflow-wrap: anywhere;
}

.searchField {
  margin-top: 15px;
}

.yearLabel {
  font-size: 50px;
}

.lightBackground {
  background: white;
}

.darkBackground {
  background: #121212;
}

.highestZIndex {
  z-index: 999999999 !important;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.searchBarTextField label,
.noResults {
  font-size: 14px !important;
}

.switchCaption {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.0333333333em !important;
  font-family: "Roboto", sans-serif !important;
}

@media (hover: hover) {
  .trackListItem:hover {
    background-color: var(--v-tertiary-base);
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  opacity: 1;
  animation-name: fadeOutOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 1270px) {
  .platinumCouchLogo {
    margin-top: 40px;
  }
}

/* 960px aka md */
@media only screen and (max-width: 960px) {
  .platinumCouchLogo {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 650px) {
  .platinumCouchLogo {
    height: 160px;
    width: 242px;
  }
  .createPlaylistSwitchContainer {
    margin-top: 15px !important;
  }
  .createPlaylistSwitchContainer,
  .highQualityAudioSwitchContainer {
    max-height: 25px;
  }
}

@media only screen and (max-width: 600px) {
  .platinumCouchLogo {
    margin-top: 20px;
  }
  .topButtonsRight {
    margin-top: -11px;
    margin-right: -6px;
  }
  .trackTitle,
  .trackListItemName,
  .trackListItemDuration {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 550px) {
  .platinumCouchLogo {
    height: 120px;
    width: 182px;
  }

  .trackTimes {
    margin-top: 9px !important;
  }

  .trackControlButtons,
  .trackListCaptions {
    margin-top: 7px !important;
  }

  .yearLabel {
    font-size: 40px;
  }
}

@media only screen and (max-width: 450px) {
  .col-1,
  .col-11 {
    padding: 0 !important;
  }
  .platinumCouchLogo {
    height: 90px;
    width: 135px;
  }
  .trackTitle,
  .trackListItemName,
  .trackListItemDuration {
    font-size: 14px !important;
  }
  .trackListItemExtraPadding {
    padding: 14px 0 14px 0;
  }
  .playlistCheckbox {
    margin-top: 8px !important;
    margin-bottom: -9px !important;
  }
  .progressSlider {
    margin-top: 4px !important;
  }
  .yearLabel {
    font-size: 30px;
  }
  .switchCaption {
    font-size: 0.57rem !important;
  }

  .v-btn--fab.v-size--x-small {
    height: 29px !important;
    width: 29px !important;
  }
}
</style>